<template>
  <div class="layout-banner-links">
    <div v-show-slide="!windowSizes.isPhonePortrait || showLayoutBannerLinks" class="layout-banner-links__list">
      <template v-if="!selectedUser">
        <esmp-button
          class="layout-banner-links__item"
          v-for="(item, index) in toolbarLinks"
          :key="index"
          @click="actionButton(item)"
          icon-position="left"
          :icon="item.icon ? item.icon : ''"
          shape="circular"
          size="small"
          :style="{'background-color': item.backgroundColor}"
        >
          <esmp-link v-if="item.path" :to="item.path" />
          {{ item.title }}
        </esmp-button>
      </template>

      <template v-if="selectedUser">
        <esmp-button
          class="layout-banner-links__item"
          @click="actionButton(portfolioLink)"
          icon-position="left"
          :icon="portfolioLink.icon"
          shape="circular"
          size="small"
          :style="{'background-color': portfolioLink.backgroundColor}"
        >
          <esmp-link :to="portfolioLink.path" />
          {{ portfolioLink.title }}
        </esmp-button>
        <esmp-button
          class="layout-banner-links__item"
          icon-position="left"
          :icon="requestFromLink.icon ? requestFromLink.icon : ''"
          @click="actionButton(requestFromLink)"
          shape="circular"
          size="small"
          :style="{'background-color': requestFromLink.backgroundColor}"
        >
          {{ requestFromLink.title }}: {{ selectedUser.fullName }}
        </esmp-button>

        <esmp-button
          class="layout-banner-links__close"
          view="function"
          icon="close"
          icon-position="left"
          @click="clearSelectedUser"
        >
          Отменить
        </esmp-button>
      </template>
    </div>

    <request-from
      :is-showed-request-from="isShowedRequestFrom"
      @hide-modal="hideModal"
    />

    <a
      v-if="windowSizes.isPhonePortrait"
      :class="[ 'layout-banner-links__button', { 'layout-banner-links__button--open': showLayoutBannerLinks }]"
      @click="setShowLayoutBannerLinks(!showLayoutBannerLinks)"
    >
      {{ showLayoutBannerLinks ? 'Свернуть' : 'Открыть' }} быстрые услуги
      <esmp-icon name="arrow-down" />
    </a>
  </div>
</template>

<script>
import Vue from 'vue';
import VShowSlide from 'v-show-slide';
import { mapActions, mapState } from 'vuex';
import { TOOLBAR_LINK_TYPES, TOOLBAR_LINKS } from '@/constants/sidebar';
import isNumber from 'lodash/isNumber';

Vue.use(VShowSlide);

const RequestFrom = () => import('@/components/request-from/');

export default {
  name: 'LayoutBannerLinks',

  components: {
    RequestFrom,
  },

  data() {
    return {
      isShowedRequestFrom: false,
      toolbarLinks: [],
    };
  },

  computed: {
    ...mapState('user', ['selectedUser']),
    ...mapState('system', ['config']),
    ...mapState('viewOptions', ['windowSizes', 'showLayoutBannerLinks']),

    requestFromLink() {
      return this.toolbarLinks.find((link) => link.code === TOOLBAR_LINK_TYPES.REQUEST_FROM);
    },

    portfolioLink() {
      return this.toolbarLinks.find((link) => link.code === TOOLBAR_LINK_TYPES.PORTFOLIO);
    },
  },

  methods: {
    ...mapActions('user', ['clearSelectedUser']),
    ...mapActions('viewOptions', ['setShowLayoutBannerLinks']),

    showModal(modalName) {
      if (modalName === 'request-from') {
        this.isShowedRequestFrom = true;
      }
    },

    hideModal(payload) {
      if (payload.modalName === 'request-from') {
        this.isShowedRequestFrom = payload.newValue;
      }
    },

    actionButton(item) {
      if (item.modal) {
        this.showModal(item.modal);
      }
      if (item.path) {
        this.$router.push({ path: item.path });
      }
    },

    setToolbarLinks() {
      const links = [];

      if (this.config.isRequestFromAvailable) {
        links.push(this.formattedLink(TOOLBAR_LINK_TYPES.REQUEST_FROM));
      }

      if (this.config.isDelegationsAvailable) {
        links.push(this.formattedLink(TOOLBAR_LINK_TYPES.DELEGATIONS));
      }

      if (this.config.isBusinessTripAvailable) {
        links.push(this.formattedLink(TOOLBAR_LINK_TYPES.BUSINESS_TRIP));
      }

      links.push(this.formattedLink(TOOLBAR_LINK_TYPES.PORTFOLIO));

      return links.sort((a, b) => a.order - b.order);
    },

    formattedLink(linkType) {
      const uiLinkIndex = this.config?.settingsUI?.bannerLinks?.findIndex((link) => link.type === linkType);
      const currentLink = TOOLBAR_LINKS[linkType];

      if (isNumber(uiLinkIndex)) {
        const uiLink = this.config?.settingsUI?.bannerLinks[uiLinkIndex];

        if (uiLink?.title) {
          currentLink.title = uiLink.title;
        }
        if (uiLink?.backgroundColor) {
          currentLink.backgroundColor = uiLink.backgroundColor;
        }
        currentLink.order = uiLinkIndex;
      }

      return currentLink;
    },
  },
  watch: {
    config: {
      handler(value) {
        if (value.settingsUI) this.toolbarLinks = this.setToolbarLinks();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.layout-banner-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: -16px;

    @at-root .accent-block & {
      margin-bottom: -20px;
      @include for-size(phone-portrait-down) {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.1), inset 0 -1px 0px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    position: relative;
    margin-top: 16px;

    &:not(:last-child) {
      margin-right: 16px;
      @include for-size(tablet-portrait-down) {
        margin-right: 8px;
      }
    }

    .esmp-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    margin-left: 16px;
    margin-top: 16px;

    .esmp-button {
      color: $color-white;
    }

    svg {
      border: 2px solid $color-white;
      border-radius: 50%;
      transition: border-color $base-animation-time;
    }

    @include hover(true) {
      .esmp-button {
        color: $primary-color-hover;
      }
      svg {
        border-color: $primary-color-hover;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 12px auto 0;
    cursor: pointer;

    .esmp-svg-icon {
      height: 20px;
      width: 20px;
      margin-left: 6px;
    }

    &--open {
      margin-top: 26px;
      .esmp-svg-icon {
        transform: scale(1, -1);
      }
    }
  }
}
</style>
